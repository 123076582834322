export interface MenuItem {
  gsid: number;
  sid: number;
  idagencia: number;
  grupo: string;
  encuesta: string;
  token: string;
}

export class MenuContainer {
  constructor(title: string, items: MenuItem[]) {
    this.title = title;
    this.items = items;
  }
  title: string;
  items: MenuItem[];
}
